import React from "react";
import {
    HomeContainer,
    HomeDescription,
    HomeTitle,
    HomeTop,
    HomeTopShapes,
    HomeLinks
} from './HomeComponents'
import {
    Diamond,
    Container,
    Telegram,
    Whatsapp,
    Github,
    Stackoverflow,
    Upwork
} from '../../constants'
import {
    homeTexts
} from '../../texts'
const Home = ({ theme }) => {
    return (
        <Container>
            <HomeContainer theme={theme}>
                <HomeTop>
                    <HomeTopShapes theme={theme}>
                        <Diamond />
                        <Diamond />
                        <Diamond />
                        <Diamond />
                    </HomeTopShapes>
                    <HomeTitle>
                        {homeTexts.title}
                    </HomeTitle>
                </HomeTop>
                <HomeDescription>
                    {homeTexts.description}
                </HomeDescription>
                <HomeLinks>
                    <a href={homeTexts.links.upwork} target="_blank">
                        <Upwork />
                    </a>
                    <a href={homeTexts.links.github} target="_blank">
                        <Github />
                    </a>
                    <a href={homeTexts.links.stackoverflow} target="_blank">
                        <Stackoverflow />
                    </a>
                    <a href={homeTexts.links.telegram} target="_blank">
                        <Telegram />
                    </a>
                    <a href={homeTexts.links.whatsapp} target="_blank">
                        <Whatsapp />
                    </a>

                </HomeLinks>
            </HomeContainer>
        </Container>
    )
}

export default Home