import React , { useState , useEffect , useRef } from "react";
import emailjs from 'emailjs-com';
import {
    FormContainer,
    FormInput,
    FormTextArea,
    FormButton,
    FormRights,
} from './FormComponents'
import {
    Container,
    Title,
    Spinner,
    Notification
} from '../../constants'
import {
    formText
} from '../../texts'

const Form = ({theme}) => {

    const [email,setEmail] = useState('')
    const [subject,setSubject] = useState('')
    const [text,setText] = useState('')
    const [show,setShow] = useState('')
    const [loading,setLoading] = useState(false)
    const formRef = useRef()
    function submit(e){
        e.preventDefault();
        if(loading) return
        setLoading(true)  
        emailjs.sendForm(process.env.GATSBY_SERVICE_ID, process.env.GATSBY_TEMPLATE_ID, formRef.current, process.env.GATSBY_USER_ID)
          .then((result) => {
            //   setName('')
              setSubject('')
              setEmail('')
              setText('')
              setShow(formText.success)
              setLoading(false)  
              setTimeout(() => {
                  setShow('')
              }, 6000)
          }, (error) => {
              setShow(formText.fail)
            //   setName('')
              setSubject('')
              setEmail('')
              setText('')
              setLoading(false)  
              setTimeout(() => {
                  setShow('')
              }, 6000)
          });
      };

    return(
        <Container>
            <Title theme={theme} title={formText.title}/>
            <FormContainer ref={formRef} theme={theme} onSubmit={(e) => submit(e)}>
                <FormInput name="from_email" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                <FormInput name="from_subject" placeholder="Subject" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} required/>
                <FormTextArea name="from_text" placeholder="......." value={text} onChange={(e) => setText(e.target.value)} required/>
                <FormButton>
                    <button type="submit">
                        {formText.button}
                        { loading && <Spinner theme={theme} />}
                    </button>
                </FormButton>
                {show !== '' && <Notification theme={theme}>{show}</Notification>}
            </FormContainer>
        </Container>
        
    )
}

export default Form