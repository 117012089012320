import React from "react";
import {
    Container,
    Github2,
    Link,
    Square,
    Title
} from '../../constants';
import { projectsTexts } from '../../texts';
import ProjectInd from './ProjectInd';
import {
    ProjectDescription,
    ProjectLink,
    ProjectOpen,
    ProjectSkill,
    ProjectSkillsContainer,
    ProjectTitle,
    ProjectsBreak,
    ProjectsContainer,
    ProjectsOpenContainer
} from "./ProjectsComponents";

const Projects = ({theme,data}) => {
    
    return (
        <Container>
            <Title theme={theme} title={projectsTexts.link} />
            <ProjectsContainer theme={theme} >
                {
                    projectsTexts.high.map(
                        (project,index) => (
                                <ProjectInd 
                                    key={`projects_project_${index}`}
                                    project={project}
                                    index={index}
                                    data={data}
                                />
                        )
                    )
                }
                <ProjectsBreak>
                    <p>
                        Some freetime projects :
                    </p>
                </ProjectsBreak>
                <ProjectsOpenContainer>
                    {
                        projectsTexts.low.map(
                            (project,index) => (
                                <ProjectOpen key={`projects__open__${index}`} >
                                    <ProjectTitle>
                                        <Square/>
                                        <p>
                                            {project.title}
                                        </p>
                                    </ProjectTitle>
                                    <ProjectDescription>
                                        {project.description}
                                    </ProjectDescription>
                                    <ProjectSkillsContainer>
                                        {
                                            project.skills.map(
                                                (skill,jndex) => (
                                                    <ProjectSkill className="projects__skill" key={`projects_project_open_${index}_${jndex}`} >
                                                        {skill}
                                                    </ProjectSkill>
                                                )
                                            )
                                        }
                                    </ProjectSkillsContainer>
                                    <ProjectLink>
                                        <a href={project.link} target="_blank" >
                                            <Link />
                                        </a>
                                        <a href={project.github} target="_blank" >
                                            <Github2 />
                                        </a>
                                        {/* <div onClick={() => navigate('/gallery#show',{state:{filter:project.gallery}})}>
                                            <GallerySvg />
                                        </div> */}
                                    </ProjectLink>
                                </ProjectOpen>
                            )
                        )
                    }
                </ProjectsOpenContainer>
            </ProjectsContainer>
        </Container>
        
    )
}

export default Projects