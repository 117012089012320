import styled from "styled-components";
import {
    colors,
    fonts
} from '../../constants'

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    color: ${({theme}) => colors[theme].font};
    align-items: center;
    justify-content: center;
    transition: color .2s ease-in;
    height:85vh;
    width: 100%;
    div:last-child{
        svg{
            fill: ${({theme}) => colors[theme].border};
            transition: fill .2s ease-in;
        }
    }
`

export const HomeTop = styled.div`
    display: flex;
`

export const HomeTopShapes = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    svg{
        margin: 5px;
        fill: ${({theme}) => colors[theme].border};
        transition: fill .2s ease-in;
    }
    svg:first-child{
        opacity:.4
        
    }
    svg:last-child{
        opacity:.4
    }
    @media screen and (max-width:400px){
        svg{
            width: 10px;
            height: 10px;
            margin-right: 2px;
        }
    }
`

export const HomeTitle = styled.h1`
    font-size: ${fonts.responsive[1]};
    @media screen and (max-width:400px){
        font-size: ${fonts.fixed[4]};
        line-height: 1.5;

    }
`

export const HomeDescription = styled.p`
    font-size: ${fonts.responsive[2]};
    @media screen and (max-width:400px){
        font-size: ${fonts.fixed[5]};
        letter-spacing: 1px;
        width: 70vw;
        margin: 20px auto;
    }

`

export const HomeLinks = styled.div`
    display: flex;
    align-items: center;
    a{
        display: flex;
        width: max-content;
        margin:30px;
    }
    svg{
        width: 50px;
        height: 50px;
    }
    a{
        transition: transform .2s ease-in;
        :hover{
            transform: translateY(-5px)
        }
    }
    @media screen and (max-width:400px){
        svg{
            width: 30px;
            height: 30px;
        }
    }
`