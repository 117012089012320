import React from "react";
import {
    AboutContainer,
    AboutLeft,
    AboutRight,
    AboutSkills,
    AboutSkillsItems,
    AboutSkillsTitle,
    AboutText
} from './AboutComponents'
import {
    aboutTexts
} from '../../texts'
import {
    Diamond,
    Square,
    Container,
    Title
} from '../../constants'

const About = ({theme}) => {
    return(
        <Container >
            <Title theme={theme}  title={aboutTexts.link} />
            <AboutContainer theme={theme} >
                <AboutLeft>
                    <AboutText>
                        <p>
                            {aboutTexts.text}
                        </p>
                    </AboutText>
                    <AboutSkills>
                        <AboutSkillsTitle>
                            <Square />
                            <p>
                                {aboutTexts.skillsTitle}
                            </p>
                        </AboutSkillsTitle>
                        <AboutSkillsItems>
                            {
                                aboutTexts.skills.map((domain,id) => (
                                    <div key={`about_domain_${id}`}>
                                        {
                                            domain.map((skill,id) => 
                                                (
                                                    <div key={`about_skill_${id}`}>
                                                        <Diamond />
                                                        <p>{skill}</p>
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </AboutSkillsItems>
                    </AboutSkills>
                </AboutLeft>
                <AboutRight>
                    <img src={aboutTexts.image} />
                </AboutRight>
            </AboutContainer>
        </Container>
    )
}

export default About