import styled from "styled-components";
import {
    colors,
    fonts
} from "../../constants";

export const ProjectsContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => colors[theme].font};
    transition: color .2s ease-in;
    .projects__project__left {
        border-color: ${({ theme }) => colors[theme].border};
    transition: height .2s ease-in;

    }
    .project__more{
        cursor: pointer;
        display: flex;
        width: max-content;
        padding-left: 10px;
        border-left: 2px solid ${({ theme }) => colors[theme].border};
        position: relative;
        transition: border .2s ease-in;
        ::after{
            content:'';
            position: absolute;
            width: 0;
            bottom: 0px;
            left: 0;
            height: 1px;
            background: ${({ theme }) => colors[theme].border};
            transition: width .4s ease-in;
        }
        :hover{
            ::after{
                width: 100%;
            }
        }
    }
    svg{
        fill: ${({ theme }) => colors[theme].border};
        transition: fill .2s ease-in;
        width: 40px;
        height: 40px;
        transition: fill .2s ease-in;
    }
    @media screen and (max-width:400px){
        svg{
            width: 20px;
            height: 20px;
        }
    }
    >div>div:first-child{
        ::after{
            border-color: ${({ theme }) => colors[theme].border};
            transition: border .2s ease-in;
        }
    }
    >div>div:last-child{
        ::after{
            background: ${({ theme }) => colors[theme].border};
        }
    }

    >div{
        display: flex;
        flex: 1;
        justify-content:space-between;
        align-content: center;
    }

    >div:last-child{
        div{
            border-color: ${({ theme }) => colors[theme].border};
        }
    }

    .projects__skill{
        background: ${({ theme }) => colors[theme].border + '20'};
        color: ${({ theme }) => colors[theme].border};
        margin: 8px;
        transition: color .2s ease-in;
    }
    
`

export const ProjectContainer = styled.div`
    display: flex;
    margin: 50px 20px;
    justify-content:space-between;
    align-content: center;
    position: relative;
    @media screen and (max-width:1200px){
        flex-direction: column;
    }
    @media screen and (max-width:400px){
        margin: 0px 0px;
        width: auto;
        margin: 20px 0px;
    }
    >div:nth-child(2){
        width: ${({ more }) => !more ? 'auto' : '0px'};
        transition: width .2s ease-in;
    }
    >div:nth-child(1)::after{
        width: ${({ more }) => !more ? '150%' : '100%'};
        transition: width .2s ease-in;
        @media screen and (max-width:1200px){
            width: 100%;
        }
    }
`

export const ProjectRight = styled.div`
    display: flex;
    height: max-content;
    margin: auto;
    z-index: 1;
    >div{
        height: 400px;
        width: 600px;
        @media screen and (max-width:1300px){
            height: 267px;
            width: 400px;
        }
        @media screen and (max-width:800px){
            height: 50vw;
            width: 75vw;
        }
    };
    transition: border .2s ease-in;
    
    position: relative;
    :hover{
        ::after{
            opacity: 0;
        }
    }

    @media screen and (max-width: 1200px){
        display: none;
    }

    ::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .4;
        transition: opacity .2s ease-in,background-color .2s ease-in;
        
    }
`

export const ProjectLeft = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
    height: ${({ height }) => `${height}px`};
    transition: height .2s ease-in;

    @media screen and (max-width:1300px){
        width: auto;
        flex: 1;
        margin: auto;
        margin-bottom: 50px;
    }
    @media screen and (max-width:400px){
        padding: 20px;
        margin-bottom: 0px;
    }
    @media screen and (max-width:400px){
        border-width: 4px;
        border-style: solid;
    }
    ::after{
        content: '';
        width: 150%;
        height: 100%;
        left: 0px;
        top: 0;
        position: absolute;
        border: 10px solid ;
        z-index: -1;
        @media screen and (max-width:1300px){
            width: calc(100% - 25px);
            margin: auto;
        }
        @media screen and (max-width:400px){
            display: none;
        }
    }
`


export const ProjectTitle = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0px;
    @media screen and (max-width:400px){
        margin: 0px 0px;

    }
    font-size: ${fonts.responsive[2]};
    svg{
        margin-right: 16px;
    }
`

export const ProjectDescription = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.fixed[4]};
    @media screen and (max-width:400px){
        letter-spacing: 1px;
    }
   
`
export const ProjectSkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px;
`

export const ProjectSkill = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    letter-spacing: 1px;
    @media screen and (max-width:400px){
        letter-spacing: 1px;
        font-size: ${fonts.fixed[6]};
        padding: 5px 10px;
    }
`

export const ProjectLink = styled.div`
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
    margin:20px 0px;
    width: max-content;
    a,div{
        margin:0 20px;
        cursor: pointer;
    }
    svg{
        width: 40px;
        height: 40px;
        @media screen and (max-width:400px){
            width: 20px;
            height: 20px;
        }
    }
    @media screen and (max-width:1300px){
        margin: 20px auto;
    }
`

export const ProjectsOpenContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

export const ProjectOpen = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 4px solid;
    width: 500px;
    height: 500px;
    margin: 10px auto;
    >div{
        margin:auto 0;
    }
    >div:last-child{
        margin-top: auto;
    }
    >div:first-child{
        margin-top: 0;
    }
    @media screen and (max-width:400px){
        padding: 15px;
        height: auto;
        marginTop: 20px;
x
        >p,>div:not(:first-child){
            margin:20px auto;
        }
    }
`

export const ProjectsBreak = styled.div`
    display: flex;
    border-bottom: 2px solid;
    width:60vw;
    margin-bottom: 50px;
    font-size: ${fonts.responsive[3]};
    @media screen and (max-width:400px){
        margin-bottom: 20px;
    }
`

export const ProjectMore = styled.button`
    font-size: ${fonts.responsive[3]};
    border: none;
    background: none; 
    color: inherit;
    margin: 8px 0px;
    font-size: 1.1rem ;
    padding-bottom: 5px;
    padding-top: 5px;
    color: ${colors.primary};
    @media screen and (max-width:400px){
        ::after{
            display: none;
        }   
    }
`