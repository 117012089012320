import * as React from "react"
import { GlobalContext } from "../GlobalContext"
import { graphql } from "gatsby"
import { findFromImages } from '../utils/functions'
import Home from "../components/Home"
import About from "../components/About"
import Projects from "../components/Projects"
import Form from "../components/Form"
import { IndexContainer } from "../constants"
const IndexPage = ({data}) => {
  const { theme } = React.useContext(GlobalContext)
  return (
    <IndexContainer theme={theme}>
      <section id="home">
            <Home theme={theme} />
      </section>
      <section id="about">
            <About theme={theme} />
      </section>
      <section id="projects">
            <Projects data={data} theme={theme} />
      </section>
      <section id="contact">
            <Form theme={theme} />
      </section>
    </IndexContainer>
  )
}

export const query = graphql`
    query MyQuery {
        allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
          edges {
              node {
              id
              name
              childImageSharp {
                  fluid(maxWidth: 2000){
                  ...GatsbyImageSharpFluid
                  }
              }
              }
          }
        }
    }
`

export default IndexPage
