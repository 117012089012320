import styled from "styled-components";
import { colors, fonts } from "../../constants";

export const AboutContainer = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => colors[theme].font};
    flex-wrap: wrap;
    svg{
        margin-right: 10px;
        fill: ${({ theme }) => colors[theme].border};
        transition:fill .2s ease-in;
    }
    transition:color .2s ease-in;
    >div{
        border-color: ${({ theme }) => colors[theme].border};
        :hover{
            ::after{
                opacity: 0;
            }
        }
        ::after{
            background-color: ${({ theme }) => colors[theme].border};
            opacity: .4;
            transition: opacity .2s ease-in;
            
        }
    }
`

export const AboutLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width:700px;
    margin: 50px;
    @media screen and (max-width:400px){
        margin: 20px;
    }
`
export const AboutRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid;
    border-radius: 50%;
    padding: 20px;
    position: relative;
    img{
        border-radius: 50%;
        width: 300px;
        height: 300px;
        @media screen and (max-width:400px){
            width: 30vw;
            height: 30vw;
        }
    }
    @media screen and (max-width:400px){
        padding: 10px;
        display: none;
    }
    ::after{
            content: '';
            position: absolute;
            width: 300px;
            height: 300px;
            top: calc(50% - 150px);
            left: calc(50% - 150px);
            z-index: 1;
            border-radius: 50%;
            transition: background-color .2s ease-in;
            @media screen and (max-width:400px){
                width: 30vw;
                height: 30vw;
                top: calc(50% - 15vw);
                left: calc(50% - 15vw);
            }
    }

`

export const AboutText = styled.div`
    display: flex;
    font-size: ${fonts.responsive[3]};
    @media screen and (max-width:400px){
        font-size: ${fonts.responsive[3]};
        p{
            letter-spacing: 1px;
        }
    }
`

export const AboutSkills = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width:400px){
        font-size: ${fonts.responsive[3]};
        p{
            letter-spacing: 1px;
        }
        svg{
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
    }
`

export const AboutSkillsTitle = styled.div`
    display: flex;
    align-items: center;
    
    @media screen and (max-width:400px){
        font-size: ${fonts.responsive[2]};
        margin: 30px 0px;
        svg{
            width: 20px;
            height: 20px
        }
    }
    
`

export const AboutSkillsItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >div>div{
        display: flex;
        align-items: center;
    }
    
`