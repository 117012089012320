import Img from 'gatsby-image';
import React, { useLayoutEffect, useRef, useState } from "react";
import {
    Link,
    Square
} from '../../constants';
import { findFromImages } from '../../utils/functions';
import {
    ProjectContainer,
    ProjectDescription,
    ProjectLeft,
    ProjectLink,
    ProjectMore,
    ProjectRight,
    ProjectSkill,
    ProjectSkillsContainer,
    ProjectTitle
} from "./ProjectsComponents";
const ProjectInd = ({ project, index, data }) => {

    const [more, setMore] = useState(false)

    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useLayoutEffect(() => {
        console.log(height, contentRef.current.scrollHeight)

        if (contentRef.current) {
            setHeight(contentRef.current.scrollHeight);
        } else {
            setHeight(undefined);
        }
    }, [more]);


    return (
        <ProjectContainer more={more}>
            <ProjectLeft >
                <ProjectTitle>
                    <Square />
                    <p>
                        {project.title}
                    </p>
                </ProjectTitle>
                <ProjectDescription>
                    {project.description}
                </ProjectDescription>
                <div
                    ref={contentRef} className="projects__project__left"
                    style={
                        {
                            height: more ? height : 0,
                            overflow: "hidden",
                            transition: "height 0.5s ease-out"
                        }
                    }
                >
                    {
                        more && project.more &&
                        <ProjectDescription>
                            {more && project.more}
                        </ProjectDescription >
                    }
                </div>
                {
                    project.more &&
                    <ProjectMore type='button' onClick={() => setMore((more) => !more)} className="project__more">
                        {
                            more ? "Less" : "See more"
                        }
                    </ProjectMore >
                }
                <ProjectSkillsContainer>
                    {
                        project.skills.map(
                            (skill, jndex) => (
                                <ProjectSkill className="projects__skill" key={`projects_project_${index}_${jndex}`} >
                                    {skill}
                                </ProjectSkill>
                            )
                        )
                    }
                </ProjectSkillsContainer>
                <ProjectLink>
                    {
                        project.link
                        &&
                        <a href={project.link} target="_blank" >
                            <Link />
                        </a>
                    }

                    {/* <div onClick={() => navigate('/gallery#show',{state:{filter:project.gallery}})}>
                        <GallerySvg />
                    </div> */}
                </ProjectLink>
            </ProjectLeft>
            <ProjectRight >
                <Img
                    imgStyle={{
                        objectFit: "fill",
                        objectPosition: "50% 50%",
                    }}
                    fluid={findFromImages(project.imageKey, data)}
                />
            </ProjectRight>
        </ProjectContainer>
    )
}

export default ProjectInd